import React from 'react';
import {Outlet} from 'react-router-dom';
import Header from '../Components/Header';

const Layout: React.FC = () => {
    return (
        <div id='layout'>
            <div className='SW-update-dialog'></div>
            <Header></Header>
            <div className='container'>
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;
