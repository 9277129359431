import {getAuth, sendPasswordResetEmail} from 'firebase/auth';
import React, {useState} from 'react';
import Breadcrumbs from '../Components/breadcrumbs';
import FormMessage from '../Components/FormMessage';

import './css/LostPassword.css';

const LostPassword: React.FC = () => {
    const [message, SetMessage] = useState('');
    const [formIsOk, SetFormIsOk] = useState<boolean>(false);

    const OnSubmitkLostPasswordForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const mailAddress = formData.get('mailAddress') as string;

        const auth = getAuth();

        SetMessage('');

        if (mailAddress === '') {
            SetMessage('メールアドレスを入力してください。');
            SetFormIsOk(false);
        } else {
            sendPasswordResetEmail(auth, mailAddress)
                .then(() => {
                    SetMessage('リセットメールを送信しました。');
                    SetFormIsOk(true);
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.error(errorCode);
                    console.error(errorMessage);

                    if (error.code === 'auth/user-not-found') {
                        SetMessage('メールアドレスが登録されていません。');
                        SetFormIsOk(false);
                    }
                });
        }
    };

    return (
        <div className='lostPasswordPage'>
            <Breadcrumbs secondLevelTitle='パスワードを忘れた方'></Breadcrumbs>
            <div className='content content-warp'>
                <form id='lostpassword-form' onSubmit={OnSubmitkLostPasswordForm}>
                    <FormMessage isOk={formIsOk} message={message}></FormMessage>
                    <p>
                        登録しているメールアドレスを入力してください。
                        <br />
                        リセットメールを登録しているメールアドレス宛にお送りします。
                    </p>
                    <div className='input-group'>
                        <label>登録しているメールアドレス</label>
                        <input type='email' name='mailAddress'></input>
                    </div>
                    <div className='center-big-button-container'>
                        <button type='submit' className='center-big-button'>
                            送信
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LostPassword;
