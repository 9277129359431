import React, {CSSProperties, useEffect, useState} from 'react';

type FormMessageProp = {
    isOk: boolean;
    message: string;
};

const FormMessage: React.FC<FormMessageProp> = (props: FormMessageProp) => {
    const [messageStyle, SetMessageStyle] = useState<CSSProperties>({});
    const [messageClassName, SetMessageClassName] = useState<string>('');

    useEffect(() => {
        if (props.isOk === true) {
            SetMessageClassName('ok-message');
        } else {
            SetMessageClassName('error-message');
        }

        if (props.message !== '') {
            SetMessageStyle({display: 'block'});
        } else {
            SetMessageStyle({display: 'none'});
        }
    }, [props.isOk, props.message]);

    return (
        <div className={'form-message ' + messageClassName} style={messageStyle}>
            {props.message}
        </div>
    );
};

export default FormMessage;
