import {getAuth} from 'firebase/auth';
import {ChangeEvent, CSSProperties, Fragment, useEffect, useState} from 'react';
import ToDoDatabase, {TaskKeyValue} from '../Firebase/ToDoDatabase';
import Task from '../Models/Task';
import {FormatDate, ScrollTop} from '../Utils/Util';
import './css/TaskListView.css';
import TaskEdit from './TaskEdit';
import TaskView from './TaskView';

type TaskListViewProps = {
    CurrentTaskList: TaskKeyValue;
    CurrentTaskListId: string;
    CurrentTaskListName: string;
};

const Tasks: React.FC<TaskListViewProps> = (props: TaskListViewProps) => {
    const [TaskList, SetTaskList] = useState<TaskKeyValue>({});
    const [Task, SetTask] = useState<Task | undefined>(undefined);
    const [DeleteModalStyle, SetDeleteModalStyle] = useState<CSSProperties>({display: 'none'});
    const [DeleteTaskId, SetDeleteTaskId] = useState('');

    const [Filter, SetFilter] = useState('すべて');

    useEffect(() => {
        ListFilter(Filter);
    });

    const ToDoChangeComplated = (id: string) => {
        if (TaskList !== undefined) {
            const task = TaskList[id];

            if (task !== undefined) {
                task.IsComplated = task.IsComplated === false ? true : false;

                const auth = getAuth();
                const userId = auth.currentUser?.uid;
                if (userId !== undefined) {
                    ToDoDatabase.SaveTask(userId, props.CurrentTaskListId, id, task);
                }
            }
        }
    };

    const ToDoDelete = (id: string) => {
        const top = ScrollTop() + 30;
        SetDeleteModalStyle({top: top, display: 'block'});

        SetDeleteTaskId(id);
    };

    const OnClickDeleteButton = () => {
        const deleteTaskElement = document.getElementById(DeleteTaskId);
        deleteTaskElement?.classList.add('is-delete');
        SetDeleteModalStyle({display: 'none'});

        setTimeout(() => {
            if (TaskList !== undefined) {
                delete TaskList[DeleteTaskId];

                const auth = getAuth();
                const userId = auth.currentUser?.uid;

                if (userId !== undefined) {
                    ToDoDatabase.DeleteTask(userId, props.CurrentTaskListId, DeleteTaskId, (error) => {
                        if (error === undefined) {
                        } else {
                            alert('エラーが発生しました。');
                            console.error(error);
                        }
                    });
                }
            }
        }, 1000);
    };

    const OnClickDeleteCancelButton = () => {
        SetDeleteModalStyle({display: 'none'});
    };

    const EditTask = (taskId: string) => {
        if (TaskList !== undefined) {
            SetTask(TaskList[taskId]);
        }
    };

    const EditTaskCloseCallback = () => {
        SetTask(undefined);
    };

    const OnChangeShowFilter = (event: ChangeEvent<HTMLSelectElement>) => {
        const filter = event.target.value;
        SetFilter(filter);

        ListFilter(filter);
    };

    /**
     * リストのフィルター処理を行います。
     * @param filter フィルター設定を指定します。
     */
    const ListFilter = (filter: string) => {
        const filterTaskList: TaskKeyValue = {};

        if (filter !== 'すべて') {
            Object.keys(props.CurrentTaskList).forEach((key) => {
                if (props.CurrentTaskList !== undefined) {
                    const task = props.CurrentTaskList[key];

                    if (filterTaskList !== undefined) {
                        if (filter === '未完了' && task.IsComplated === false) {
                            filterTaskList[key] = task;
                        } else if (filter === '完了' && task.IsComplated === true) {
                            filterTaskList[key] = task;
                        }
                    }
                }
            });

            SetTaskList(filterTaskList);
        } else {
            SetTaskList(props.CurrentTaskList);
        }
    };

    let currentDate: Number = Number.MAX_VALUE;

    return (
        <div className='task-list'>
            <div className='list-name'>{props.CurrentTaskListName}</div>
            <TaskEdit Task={Task} Callback={EditTaskCloseCallback} CurrentListName={props.CurrentTaskListId}></TaskEdit>
            <div className='todos'>
                <div className='todo-control'>
                    絞り込み
                    <select onChange={OnChangeShowFilter}>
                        <option value='すべて'>すべて</option>
                        <option value='未完了'>未完了</option>
                        <option value='完了'>完了</option>
                    </select>
                </div>
                <div className='todo-wrapper'>
                    {Object.keys(TaskList).map((key) => {
                        const task = TaskList[key];
                        const formatedDate = FormatDate(task.DateCreated);
                        const creteDate = new Date(formatedDate);

                        if (creteDate.getTime() < currentDate) {
                            currentDate = creteDate.getTime();
                            return (
                                <Fragment key={key}>
                                    <div className='content date bold'>{formatedDate}</div>
                                    <TaskView
                                        Id={key}
                                        Task={task}
                                        ComplateHandle={ToDoChangeComplated}
                                        EditHandle={EditTask}
                                        DeleteHandle={ToDoDelete}
                                    ></TaskView>
                                </Fragment>
                            );
                        }

                        return (
                            <TaskView
                                key={key}
                                Id={key}
                                Task={task}
                                ComplateHandle={ToDoChangeComplated}
                                EditHandle={EditTask}
                                DeleteHandle={ToDoDelete}
                            ></TaskView>
                        );
                    })}
                </div>
            </div>
            <div className='modal' style={DeleteModalStyle}>
                <div className='modal-message'>削除しますか?</div>
                <div className='modal-control'>
                    <button className='button-danger' onClick={OnClickDeleteButton}>
                        削除
                    </button>
                    <button className='button-outline' onClick={OnClickDeleteCancelButton}>
                        キャンセル
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Tasks;
