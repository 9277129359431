import React, {useEffect, useState} from 'react';
import {getAuth} from 'firebase/auth';
import ToDoDatabase, {TaskKeyValue} from '../Firebase/ToDoDatabase';
import TaskListView from '../Components/TaskListView';
import './css/Home.css';
import ListControl from '../Components/ListControl';
import {useParams} from 'react-router-dom';
import TaskList from '../Models/TaskList';

const Home = () => {
    const [CurrentTaskList, SetCurrentTaskList] = useState<TaskKeyValue>({});
    const [CurrentTaskListName, SetCurrentTaskListName] = useState('');
    const [CurrentTaskListId, SetCurrentTaskListId] = useState('');
    const [DefaultTaskListId, SetDefaultTaskListId] = useState('');

    const params = useParams();

    useEffect(() => {
        const auth = getAuth();
        const userId = auth.currentUser?.uid;

        if (userId !== undefined) {
            if (params !== undefined && params['listId'] !== undefined) {
                const taskListId = params['listId'];

                ToDoDatabase.LoadTaskList(userId, taskListId, (taskList) => {
                    SetDefaultTaskListId(taskList.Id);
                    SetCurrentTaskListId(taskList.Id);
                    SetCurrentTaskListName(taskList.Name);

                    LoadTasks(userId, taskList);
                });
            } else {
                ToDoDatabase.GetDefalutTaskList(userId, (taskList) => {
                    SetDefaultTaskListId(taskList.Id);
                    SetCurrentTaskListId(taskList.Id);
                    SetCurrentTaskListName(taskList.Name);

                    LoadTasks(userId, taskList);
                });
            }
        }
    }, [params]);

    /**
     * タスクの読込を行います。
     * @param userId ユーザーIDを指定します。
     * @param taskList タスクリストオブジェクトを指定します。
     */
    const LoadTasks = (userId: string, taskList: TaskList) => {
        ToDoDatabase.LoadTasks(userId, taskList.Id, (tasks, error) => {
            if (error === undefined) {
                SetCurrentTaskList(tasks);
            } else {
                console.error(error);
            }
        });
    };

    return (
        <div className='HomePage'>
            <ListControl
                DefaultTaskListId={DefaultTaskListId}
                OnChangeCurrentTaskList={SetCurrentTaskList}
                OnChangeCurrentTaskListId={SetCurrentTaskListId}
                OnChangeCurrentTaskListName={SetCurrentTaskListName}
            ></ListControl>
            <TaskListView CurrentTaskList={CurrentTaskList} CurrentTaskListName={CurrentTaskListName} CurrentTaskListId={CurrentTaskListId}></TaskListView>
        </div>
    );
};

export default Home;
