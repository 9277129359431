import {getAuth} from 'firebase/auth';
import {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {DisableBodySchroll} from '../Utils/Util';
import {useAuthContext} from './AuthComponents';

const Header: React.FC = () => {
    const [headerClassName, SetHeaderClassName] = useState('');
    const [headerMenuClassName, SetHeaderMenuClassName] = useState('');
    const [menuButtonClassName, SetMenuButtonClassName] = useState('');

    const authContext = useAuthContext();
    const auth = getAuth();
    const navigate = useNavigate();

    const Logout = () => {
        auth.signOut()
            .then(() => {
                alert('ログアウトしました。');
                authContext.signout(() => {
                    navigate('/');
                });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const OnClickMenu = () => {
        if (menuButtonClassName === '') {
            SetHeaderClassName('header-full');
            SetHeaderMenuClassName('header-menu-active');
            SetMenuButtonClassName('active');
            DisableBodySchroll(true);
        } else {
            SetHeaderClassName('');
            SetHeaderMenuClassName('');
            SetMenuButtonClassName('');
            DisableBodySchroll(false);
        }
    };

    const OnClickMenuLink = () => {
        SetHeaderClassName('');
            SetHeaderMenuClassName('');
            SetMenuButtonClassName('');
        DisableBodySchroll(false);
    }

    return (
        <header id='header' className={headerClassName}>
            <div className='header-container'>
                <div>
                    <Link to='/'>
                        <h1 className='header-logo'>Desktop ToDo</h1>
                    </Link>
                </div>
                <div id='header-menu-containar'>
                    <ul id='header-menu' className={headerMenuClassName}>
                        <li className='nav-item'>
                            <Link to='/' onClick={OnClickMenuLink}>
                                ToDo List
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/setting' onClick={OnClickMenuLink}>
                                設定
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/privacy' onClick={OnClickMenuLink}>
                                プライバシーポリシー
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <a href='https://solarsail.work/contact' target='_blank' rel='noreferrer'>
                                お問い合わせ<span className='material-symbols-outlined icon-small'>exit_to_app</span>
                            </a>
                        </li>
                        <li className='nav-item' onClick={OnClickMenu}>
                            <Link to='/releaseNote'>リリースノート</Link>
                        </li>
                    </ul>
                    <button className={'menu-trigger ' + menuButtonClassName} id='menu-button' onClick={OnClickMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    <div onClick={Logout} id='logout'>
                        <span className='material-symbols-outlined symbol-animation'>logout</span>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
