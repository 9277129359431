import React from 'react';
import Breadcrumbs from '../Components/breadcrumbs';

import './css/ReleaseNote.css';

const ReleaseNote: React.FC = () => {
    return (
        <div className='releaseNotePage'>
            <Breadcrumbs secondLevelTitle='リリースノート'></Breadcrumbs>
            <div className='content content-warp'>
                <div id='release-note-content' className='content-max'>
                    <dl>
                        <dt>2022/09/29</dt>
                        <dd>
                            <ul>
                                <li>リスト機能の実装</li>
                                <li>リストページを追加してリストへ直接リンクできるように機能を追加</li>
                            </ul>
                        </dd>
                        <dt>2022/09/09</dt>
                        <dd>
                            <ul>
                                <li>リスト機能の仮実装</li>
                                <li>ログイン周りの動作を変更</li>
                            </ul>
                        </dd>
                        <dt>2022/08/15</dt>
                        <dd>
                            <ul>
                                <li>リスト対応の準備時にタスクの削除と絞り込みが機能していなかったのを修正</li>
                            </ul>
                        </dd>
                        <dt>2022/08/13</dt>
                        <dd>
                            <ul>
                                <li>リスト対応の準備</li>
                            </ul>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    );
};

export default ReleaseNote;
