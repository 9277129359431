import {getAuth} from 'firebase/auth';
import React, {CSSProperties, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import ToDoDatabase, {TaskKeyValue} from '../Firebase/ToDoDatabase';
import {TaskLists} from '../Models/TaskList';
import {ScrollTop} from '../Utils/Util';

type ListControlProps = {
    OnChangeCurrentTaskList: (TaskList: TaskKeyValue) => void;
    OnChangeCurrentTaskListName: (taskListName: string) => void;
    OnChangeCurrentTaskListId: (taskListId: string) => void;
    DefaultTaskListId: string;
};

const ListControl: React.FC<ListControlProps> = (props: ListControlProps) => {
    const [CurrentTaskListId, SetCurrentTaskListId] = useState('');
    const [AddListModalClassName, SetAddListModalClassName] = useState<string>('');
    const [AddListModalStyle, SetAddListModalStyle] = useState<CSSProperties>({display: 'none'});
    const [Lists, SetLists] = useState<TaskLists>({});
    const [ListName, SetListName] = useState('');
    const [listErrorMessage, setListErrorMessage] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth();
        const userId = auth.currentUser?.uid;

        if (userId !== undefined) {
            ToDoDatabase.LoadLists(userId, (lists) => {
                SetLists(lists);
            });
        }

        SetCurrentTaskListId(props.DefaultTaskListId);
    }, [props.DefaultTaskListId]);

    const OnClickCreateListButton = () => {
        ModalShow(true);
    };

    const OnModalClose = () => {
        ModalShow(false);
    };

    const ModalShow = (isShow: boolean) => {
        if (isShow === true) {
            const top = ScrollTop() + 30;
            SetAddListModalStyle({display: 'block', top: top});
            SetAddListModalClassName('modal');
        } else {
            SetAddListModalStyle({display: 'none'});
            SetAddListModalClassName('');
        }
    };

    const OnClickSaveListButton = () => {
        const auth = getAuth();
        const userId = auth.currentUser?.uid;

        if (userId !== undefined && ListName !== '') {
            ToDoDatabase.SaveList(userId, ListName, (error) => {
                if (error !== undefined) {
                    setListErrorMessage(error);
                } else {
                    SetListName('');
                    setListErrorMessage('');
                    ModalShow(false);

                    ToDoDatabase.LoadLists(userId, (lists) => {
                        SetLists(lists);
                    });
                }
            });
        }
    };

    const OnChangeListName = (event: React.FormEvent<HTMLInputElement>) => {
        SetListName(event.currentTarget.value);
    };

    const OnChangeTaskList = (event: React.FormEvent<HTMLSelectElement>) => {
        const target = event.currentTarget;
        const taskListId = target.value;
        const taskListName = Lists[taskListId].Name;

        const auth = getAuth();
        const userId = auth.currentUser?.uid;

        if (userId !== undefined) {
            ToDoDatabase.LoadTasks(userId, taskListId, (tasks, error) => {
                props.OnChangeCurrentTaskListName(taskListName);
                props.OnChangeCurrentTaskListId(taskListId);
                SetCurrentTaskListId(taskListId);

                if (error === undefined) {
                    props.OnChangeCurrentTaskList(tasks);
                } else {
                    props.OnChangeCurrentTaskList({});
                    console.error(error);

                    console.error('from list control');
                }
            });
        }

        navigate(`/${taskListId}`);
    };
    return (
        <div id='list-control'>
            <h3>リスト関連</h3>
            <button onClick={OnClickCreateListButton}>
                <span className='material-symbols-outlined'>add</span>
                <span className='button-text'>リスト追加</span>
            </button>
            <select onChange={OnChangeTaskList} value={CurrentTaskListId}>
                {Object.keys(Lists).map((taskListId) => {
                    const taskListName = Lists[taskListId].Name;
                    return (
                        <option key={taskListId} value={taskListId}>
                            {taskListName}
                        </option>
                    );
                })}
            </select>
            <div id='add-modal' className={AddListModalClassName} style={AddListModalStyle}>
                <div className='mobal-close'>
                    <span className='material-symbols-outlined' onClick={OnModalClose}>
                        cancel
                    </span>
                </div>
                <div className='modal-content'>
                    <form>
                        <input type='text' placeholder='リスト名' value={ListName} onChange={OnChangeListName}></input>
                        <button type='button' onClick={OnClickSaveListButton}>
                            <span className='material-symbols-outlined'>add</span>
                            <span className='button-text'>リスト追加</span>
                        </button>
                        {listErrorMessage && <div className='error-message'>{listErrorMessage}</div>}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ListControl;
