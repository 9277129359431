import {createUserWithEmailAndPassword, getAuth} from 'firebase/auth';
import {CSSProperties, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import Breadcrumbs from '../Components/breadcrumbs';
import FormMessage from '../Components/FormMessage';
import {AuthErrorList} from '../Firebase/AuthError';
import {GetInputValue, ValidateMailAddress} from '../Utils/Util';

import './css/Register.css';

const Register: React.FC = () => {
    const mailAddressRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const passwordRetypeRef = useRef<HTMLInputElement>(null);

    const [registerFormStyle, SetRegisterFormStyle] = useState<CSSProperties>({});
    const [goToDoStyle, SetGoToDoStyle] = useState<CSSProperties>({});

    const [isRegisterFormOk, SetIsRegisterFormOk] = useState<boolean>(false);
    const [registerFormMessage, SetRegisterFormMessage] = useState<string>('');

    const ChangeRegisterMessage = (isOk: boolean, message: string) => {
        SetIsRegisterFormOk(isOk);
        SetRegisterFormMessage(message);
    };

    const OnClickRegisterButton = () => {
        const mailAddress = GetInputValue(mailAddressRef);
        const password = GetInputValue(passwordRef);
        const passwordRetype = GetInputValue(passwordRetypeRef);

        SetRegisterFormMessage('');

        const mailAddressCheck = ValidateMailAddress(mailAddress);

        if (mailAddressCheck === false) {
            ChangeRegisterMessage(false, '正しいメールアドレスを入れてください。');
        } else if (password !== passwordRetype) {
            ChangeRegisterMessage(false, 'パスワードが一致しません。');
        } else {
            const auth = getAuth();
            createUserWithEmailAndPassword(auth, mailAddress, password)
                .then((userCredential) => {
                    ChangeRegisterMessage(true, '登録しました。');
                    SetRegisterFormStyle({display: 'none'});
                    SetGoToDoStyle({display: 'block'});
                })
                .catch((error) => {
                    const errorCode = error.code as string;

                    const errorMessage = AuthErrorList[errorCode] ?? `${errorCode}: ${error.message}`;

                    ChangeRegisterMessage(false, errorMessage);
                });
        }

        console.log(mailAddress, password, passwordRetype);

        return false;
    };

    return (
        <div className='registerPage'>
            <Breadcrumbs secondLevelTitle='新規登録'></Breadcrumbs>
            <div className='content content-warp'>
                <FormMessage isOk={isRegisterFormOk} message={registerFormMessage}></FormMessage>
                <div id='go-todo' style={goToDoStyle}>
                    <Link to='/'>ToDo Listへ</Link>
                </div>
                <form id='register-form' style={registerFormStyle}>
                    <div className='input-group'>
                        <label>メールアドレス</label>
                        <input type='email' ref={mailAddressRef} placeholder='mailAddress'></input>
                    </div>
                    <div className='input-group'>
                        <label>パスワード</label>
                        <input type='password' ref={passwordRef} placeholder='password'></input>
                    </div>
                    <div className='input-group'>
                        <label>パスワード再入力</label>
                        <input type='password' ref={passwordRetypeRef} placeholder='re-type password'></input>
                    </div>
                    <div className='center-big-button-container'>
                        <button type='button' className='center-big-button' onClick={OnClickRegisterButton}>
                            登録
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Register;
