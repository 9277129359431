import React, {useState} from 'react';
import {useLocation, Navigate} from 'react-router-dom';
import {getAuth, signInWithEmailAndPassword, signOut} from 'firebase/auth';

interface AuthContextType {
    user: any;
    from: string;
    signin: (mailAddress: string, password: string, callback: (error: any, setError: any) => void) => void;
    signout: (callback: VoidFunction) => void;
}

const AuthContext = React.createContext<AuthContextType>(null!);

const UseAuthContext = () => {
    return React.useContext(AuthContext);
};

const AuthProvider = ({children}: {children: React.ReactNode}) => {
    const [user, setUser] = useState<any>(null);
    const auth = getAuth();

    const signin = (mailAddress: string, password: string, callback: (error: any, setUser: any) => void) => {
        return signInWithEmailAndPassword(auth, mailAddress, password)
            .then(() => {
                setUser(auth.currentUser);
                callback(undefined, setUser);
            })
            .catch((error) => {
                callback(error, setUser);
            });
    };

    const signout = (callback: VoidFunction) => {
        return signOut(auth)
            .then(() => {
                setUser(null);
                callback();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const from = '';

    const value: AuthContextType = {
        user,
        from,
        signin,
        signout
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const RequireAuth = ({children}: {children: JSX.Element}) => {
    const authContext = UseAuthContext();
    const location = useLocation();

    authContext.from = location.pathname;

    console.log(authContext.from);

    const auth = getAuth();

    const user = auth.currentUser;

    if (!user) {
        return <Navigate to='/login' state={{from: location}} replace />;
    }

    return children;
};

export {AuthProvider, RequireAuth, UseAuthContext as useAuthContext};
