import React from 'react';
import {Link} from 'react-router-dom';

type BreadCrumbsProp = {
    secondLevelTitle: string;
};

const Breadcrumbs: React.FC<BreadCrumbsProp> = (props: BreadCrumbsProp) => {
    return (
        <div className='nav'>
            <nav>
                <Link to='/'>TOP</Link> &gt; {props.secondLevelTitle}
            </nav>
        </div>
    );
};

export default Breadcrumbs;
