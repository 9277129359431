import { firebaseApp } from './Firebase';
import { get, getDatabase, onValue, query, ref, remove, set } from 'firebase/database';
import Task from '../Models/Task';
import TaskList, { TaskLists } from '../Models/TaskList';
import { RandomText } from '../Utils/Util';

type TaskKeyValue = { [key: string]: Task }

class ToDoDatabase {
    private readonly database = getDatabase(firebaseApp);

    /**
     * タスクの保存を行います。
     * @param userId ユーザーIDを指定します。
     * @param listName リスト名を指定します。
     * @param taskId タスクIDを指定します。
     * @param task 保存するタスクを指定します。
     */
    public SaveTask(userId: string, listName: string, taskId: string, task: Task) {
        const path = `/tasks/${userId}/${listName}/${taskId}`;

        set(ref(this.database, path), task);
    }

    /**
     * タスクリストの読込を行います。
     * @param userId 読込を行うユーザーIDを指定します。
     * @param taskListId 読込を行うリストIDを指定します。
     * @param callback 読込後に実行するコールバックを指定します。
     */
    public LoadTasks(userId: string, taskListId: string, callback: (tasks: TaskKeyValue, error: any) => void) {
        const dbRef = query(ref(this.database, `/tasks/${userId}/${taskListId}`));

        onValue(dbRef, (snapshot) => {
            if (snapshot.exists()) {
                const tasks = snapshot.val();

                if (tasks !== undefined) {
                    const sortedTasks = this.SortTaskList(tasks);

                    callback(sortedTasks, undefined);
                }
            } else {
                const ret = {}
                callback(ret, 'no data');
            }
        });
    }

    public DeleteTask(userId: string, listName: string, id: string, callback: (error: any) => void) {
        const dbRef = ref(this.database, `/tasks/${userId}/${listName}/${id}`);
        remove(dbRef).then(() => {
            callback(undefined);
        }).catch((error) => {
            callback(error);
        })
    }

    public DeleteAllData(userId: string, callback: (error: any) => void) {
        const dbRef = ref(this.database, `/tasks/${userId}`);
        remove(dbRef).then(() => {
            callback(undefined);
        }).catch((error) => {
            callback(error);
        })
    }

    public SaveList(userId: string, listName: string, callback: (error: any) => void) {
        const listId = RandomText();

        const list = new TaskList();
        list.Id = listId;
        list.Name = listName;
        list.DateCreated = Date.now();

        const dbRef = ref(this.database, `/taskLists/${userId}/${listId}`);

        set(dbRef, list).then(() => {
            callback(undefined);
        }).catch((error) => {
            callback(error);
        })
    }

    public LoadTaskList(userId: string, taskListId: string, callback: (taskList: TaskList) => void) {
        const dbRef = ref(this.database, `/taskLists/${userId}/${taskListId}`);

        onValue(dbRef, (snapshot) => {
            const isExsits = snapshot.exists();
            if (isExsits === true) {
                const list = snapshot.val();

                if (list !== undefined) {
                    callback(list);
                } else {
                    console.error('load list error');
                }
            } else {
                console.error('load list error');
            }
        });
    };

    public LoadLists(userId: string, callback: (toDoLists: TaskLists) => void) {
        const dbRef = ref(this.database, `/taskLists/${userId}`);

        onValue(dbRef, (snapshot) => {
            const isExsits = snapshot.exists();
            if (isExsits === true) {
                const lists = snapshot.val();

                if (lists !== undefined) {
                    callback(lists);
                }
            }
        });
    };

    public GetDefalutTaskList(userId: string, callback: (taskList: TaskList) => void) {
        const dbRef = ref(this.database, `/taskLists/${userId}`);

        onValue(dbRef, (snapshot) => {
            const isExsits = snapshot.exists();
            if (isExsits === true) {
                const taskLists = snapshot.val();

                if (taskLists !== undefined) {
                    for (const taskListId in taskLists) {
                        const taskList = taskLists[taskListId] as TaskList;

                        if (taskList.Name === 'default') {
                            callback(taskList);
                        }
                    }
                }
            }
        });
    }

    public Save(path: string, data: any, callback: (erorr: any) => void) {
        const dbRef = ref(this.database, path);

        set(dbRef, data).then(() => {
            callback(undefined);
        }).catch((error) => {
            callback(error);
        });
    }

    public Load(path: string, callback: (value: any, error: any) => void) {
        const dbRef = ref(this.database, path);
        const loadQuery = query(dbRef);

        get(loadQuery).then((snapshot) => {
            const exists = snapshot.exists()

            if (exists === true) {
                const value = snapshot.val();

                callback(value, undefined);
            } else {
                callback(undefined, 'no data');
            }
        }).catch((error) => {
            callback(undefined, error);
        });
    }

    private SortTaskList(taskList: TaskKeyValue) {
        const array = Object.keys(taskList).map((id) => ({ id: id, value: taskList[id] }));

        array.sort((taskA, taskB) => {
            return taskB.value.DateCreated - taskA.value.DateCreated;
        });

        const sortedTaskList = Object.assign({}, ...array.map((item) => ({
            [item.id]: item.value,
        })));

        return sortedTaskList;
    }
}

export default new ToDoDatabase();

export type { TaskKeyValue }