import React, {Fragment, useEffect, useState} from 'react';
import Task from '../Models/Task';
import {FormatDateTime} from '../Utils/Util';

import './css/TaskView.css';

type TaskViewProps = {
    Id: string;
    Task: Task;
    ComplateHandle: (id: string) => void;
    EditHandle: (id: string) => void;
    DeleteHandle: (id: string) => void;
};

const TaskView: React.FC<TaskViewProps> = (props: TaskViewProps) => {
    const [className, SetClassName] = useState<string>('');
    const [createDate, SetCreateDate] = useState<string>('');
    
    useEffect(() => {
        const formatedCreateDate = FormatDateTime(props.Task.DateCreated);

        const classNames = new Array<string>();
        classNames.push('content');
        classNames.push('todo');

        if (props.Task.IsComplated === true) {
            classNames.push('is-completed');
        }

        const className = classNames.join(' ');

        SetClassName(className);
        SetCreateDate(formatedCreateDate);
    }, [props.Task.IsComplated, props.Task.DateCreated]);

    const OnClickComplateButton = () => {
        props.ComplateHandle(props.Id);

        const classNames = new Array<string>();
        classNames.push('content');
        classNames.push('todo');

        if (props.Task.IsComplated === true) {
            classNames.push('is-completed');
        }

        const className = classNames.join(' ');

        SetClassName(className);
    };

    const OnClickDeleteButton = () => {
        props.DeleteHandle(props.Id);
    };

    const OnClickEditButton = () => {
        props.EditHandle(props.Id);
    };

    return (
        <div className={className} id={props.Id}>
            <div className='task-buttons'>
                <div>
                    <div onClick={OnClickComplateButton} className='done-button'>
                        <span className='material-symbols-outlined symbol-animation'>done</span>
                    </div>
                    <div onClick={OnClickEditButton} className='edit-button'>
                        <span className='material-symbols-outlined symbol-animation'>edit</span>
                    </div>
                </div>
                <div onClick={OnClickDeleteButton} className='delete-button'>
                    <span className='material-symbols-outlined symbol-animation'>delete</span>
                </div>
            </div>
            <div className='task-content'>
                <div>
                    {props.Task.Value.split('\n').map((item, index) => {
                        return (
                            <Fragment key={index}>
                                {item}
                                <br />
                            </Fragment>
                        );
                    })}
                </div>
                <div className='create-date'>{createDate}</div>
            </div>
        </div>
    );
};

export default TaskView;
