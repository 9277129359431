const TaskTypeList = {
    Default: 'Default',
    DateOnly: 'DateOnly'
} as const;
type TaskType = typeof TaskTypeList[keyof typeof TaskTypeList];

class Task {
    /**
     * タスクIDを取得/設定します。
     */
    public TaskId: string = '';


    /**
     * タスクの内容を取得/設定します。
     */
    public Value: string = '';

    /**
     * タスクが完了したかを取得/設定します。
     */
    public IsComplated: boolean = false;

    /**
     * タスクの種別を取得/設定します。
     */
    public TaskType: TaskType = TaskTypeList.Default;

    /**
     * タスクの作成日時を取得/設定します。
     */
    public DateCreated: number = new Date().getTime();
}

export default Task;
export { TaskTypeList }
export type { TaskType }