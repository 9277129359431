import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {AuthProvider, RequireAuth} from './Components/AuthComponents';

import './App.css';

import Home from './Routes/Home';
import Login from './Routes/Login';
import Register from './Routes/Register';
import LostPassword from './Routes/LostPassword';
import Layout from './Routes/Layout';
import Setting from './Routes/Setting';
import Privacy from './Routes/Privacy';
import ReleaseNote from './Routes/ReleaseNote';

function App() {
    return (
        <AuthProvider>
            <Routes>
                <Route element={<Layout />}>
                    <Route path='/login' element={<Login></Login>} />
                    <Route path='/register' element={<Register></Register>} />
                    <Route path='/lostpassword' element={<LostPassword></LostPassword>} />
                    <Route path='/privacy' element={<Privacy></Privacy>} />
                    <Route path='/releaseNote' element={<ReleaseNote></ReleaseNote>} />
                    <Route
                        path='/'
                        element={
                            <RequireAuth>
                                <Home></Home>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path='/:listId'
                        element={
                            <RequireAuth>
                                <Home></Home>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path='/setting'
                        element={
                            <RequireAuth>
                                <Setting></Setting>
                            </RequireAuth>
                        }
                    />
                </Route>
            </Routes>
        </AuthProvider>
    );
}

export default App;
