const DayList = [
    '日',
    '月',
    '火',
    '水',
    '木',
    '金',
    '土'
]


/**
 * 日付を文字列にフォーマットします。(ex: 2022-01-01 00:00:00)
 * @param dateTime フォーマットする日付を指定します。
 * @returns フォーマットした日付を返します。
 */
const FormatDateTime = (dateTime: number) => {
    const date = new Date(dateTime);

    const yyyy = date.getFullYear();
    const mm = ('00' + (date.getMonth() + 1)).slice(-2);
    const dd = ('00' + date.getDate()).slice(-2);

    const H = ('00' + date.getHours()).slice(-2);
    const m = ('00' + date.getMinutes()).slice(-2);
    const s = ('00' + date.getSeconds()).slice(-2);

    const ret = `${yyyy}/${mm}/${dd} ${H}:${m}:${s}`;

    return ret;
}

/**
 * 日付を文字列にフォーマットします。(ex: 2022-01-01 (土))
 * @param dateString フォーマットする日付文字列を指定します。
 * @returns フォーマットした日付を返します。
 */
const FormatDate = (dateTime: number) => {
    const date = new Date(dateTime);

    const yyyy = date.getFullYear();
    const mm = ('00' + (date.getMonth() + 1)).slice(-2);
    const dd = ('00' + date.getDate()).slice(-2);
    const day = date.getDay();

    const ret = `${yyyy}/${mm}/${dd} (${DayList[day]})`;

    return ret;
}

/**
 * input要素の値をrefObjectを使って取得します。
 * @param refObject 取得するrebObjectを指定します。
 * @returns 取得した値を返します。
 */
const GetInputValue = (refObject: React.RefObject<HTMLInputElement>) => {
    let ret = '';

    if (refObject != null) {
        if (refObject.current != null) {
            ret = refObject.current.value;
        }
    }

    return ret;
};

/**
 * ランダムな文字列を生成します。
 * @returns ランダムな文字列を返します。
 */
const RandomText = () => {
    const length = 32;
    // 生成する文字列に含める文字セット
    const table = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-@_";
    const tableLength = table.length;
    let ret = '';
    for (var i = 0; i < length; i++) {
        ret += table[Math.floor(Math.random() * tableLength)];
    }

    return ret;
}

/**
 * 要素の指定したスタイルを取得します。
 * @param element 取得する要素を指定します。
 * @param styleProperty 取得するスタイルを指定します。
 * @returns 取得したスタイルを返します。取得できなかった場合はundefinedを返します。
 */
const GetStyle = (element: HTMLElement, styleProperty: string) => {
    let ret: string | undefined;

    if (element) {
        ret = window.document.defaultView?.getComputedStyle(element, null).getPropertyValue(styleProperty);
    }

    return ret;
}

/**
 * 正しいメールアドレスかチェックをします。
 * @param mailAddress チェックするメールアドレスを指定します。
 * @returns 正しいメールアドレスならtrueを、正しくない場合はfalseを返します。
 */
const ValidateMailAddress = (mailAddress: string) => {
    const mailAddressCheck = /[a-zA-Z0-9]+[a-zA-Z0-9._-]*@[a-zA-Z0-9_-]+[a-zA-Z0-9._-]+/;

    const ret = mailAddressCheck.test(mailAddress);

    return ret;
}

/**
 * 要素の指定したクラス名をトグルします。
 * @param element トグルするクラスがある要素を指定します。
 * @param toggleClassName トグルするクラス名を指定します。
 */
const ToggleClass = (element: HTMLElement, toggleClassName: string) => {
    let className = element.className;

    const toggleClassNameRegex = new RegExp(`${toggleClassName}`);

    const isExsist = toggleClassNameRegex.test(className);

    if (isExsist === true) {
        className = className.replace(toggleClassName, '');
    } else {
        className = className + ` ${toggleClassName}`;
    }

    className = className.trim();
    element.className = className;
}

/**
 * Bodyスクロールを無効にします。
 * @param isDisable trueの場合は無効、falseの場合は有効にします。
 */
const DisableBodySchroll = (isDisable: boolean) => {
    const bodyElements = document.getElementsByTagName('body');

    if (bodyElements !== null) {
        const element = bodyElements[0];

        if (isDisable === false) {
            element.classList.remove('schroll-disable');
        } else {
            element.classList.add('schroll-disable');
        }
    }
}

/**
 * スクロール位置を返します。
 * @returns スクロール位置を返します。
 */
const ScrollTop = () => {
    return Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
};

export { FormatDateTime, FormatDate, GetInputValue, RandomText, GetStyle, ValidateMailAddress, ToggleClass, DisableBodySchroll, ScrollTop };