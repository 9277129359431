// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyATXcZJOoNbkmfGvFSdUaApQwM8prinXlI",
    authDomain: "desktoptodoapp.firebaseapp.com",
    databaseURL: "https://desktoptodoapp-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "desktoptodoapp",
    storageBucket: "desktoptodoapp.appspot.com",
    messagingSenderId: "1060087626796",
    appId: "1:1060087626796:web:69a085ad79e7c7b8682589",
    measurementId: "G-8F1CXHT7X7"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);



export { firebaseApp, analytics };