import {getAuth, updatePassword} from 'firebase/auth';
//import {getDatabase, onValue, ref, set} from 'firebase/database';
import React, {useEffect, useState} from 'react';
import Breadcrumbs from '../Components/breadcrumbs';
import FormMessage from '../Components/FormMessage';
//import {firebaseApp} from '../Firebase/Firebase';
import ToDoDatabase from '../Firebase/ToDoDatabase';
//import TaskList from '../Models/TaskList';
//import {RandomText} from '../Utils/Util';

import './css/Setting.css';

const Setting: React.FC = () => {
    /*
    const [mailAddressChangeMessage, SetMailAddressChangeMessage] = useState('');
    const [isMailAddressChangeFormOk, SetMailAddressFormIsOk] = useState<boolean>(false);
    */

    const [passwordChangeMessage, SetPasswordChangeMessage] = useState('');
    const [isPasswordFormOk, SetPasswordFormIsOk] = useState<boolean>(false);

    const [username, SetUsername] = useState('');

    /*
    const OnSubmitChangeEmailForm = (event: React.FormEvent<HTMLFormElement>) => {};
    */

    useEffect(() => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user !== null) {
            const path = `/users/${user?.uid}`;

            ToDoDatabase.Load(path, (value, error) => {
                if (value !== undefined && error === undefined) {
                    SetUsername(value.name);
                }
            });
        }
    });

    const OnSubmitChangePasswordForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const auth = getAuth();
        const user = auth.currentUser;

        if (user !== null) {
            SetPasswordChangeMessage('');

            const formData = new FormData(event.currentTarget);
            const password = formData.get('password') as string;
            const retypePassword = formData.get('retype-password') as string;

            if (password !== '' && retypePassword !== '') {
                if (password === retypePassword) {
                    updatePassword(user, password)
                        .then(() => {
                            SetPasswordChangeMessage('パスワードを変更しました。');
                            SetPasswordFormIsOk(true);
                        })
                        .catch((error) => {
                            SetPasswordChangeMessage('パスワードを変更に失敗しました。');
                            SetPasswordFormIsOk(false);

                            console.error(error);
                        });
                } else {
                    SetPasswordChangeMessage('パスワードが一致しません。');
                    SetPasswordFormIsOk(false);
                }
            }
        }
    };

    const OnSubmitChangeUserNameForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const auth = getAuth();
        const user = auth.currentUser;

        if (user !== null) {
            const userId = user.uid;

            const path = `/users/${userId}`;

            const formData = new FormData(event.currentTarget);
            const newUsername = formData.get('name') as string;

            ToDoDatabase.Save(
                path,
                {
                    name: newUsername,
                },
                (error) => {
                    if (error !== undefined) {
                        console.log(error);
                    }
                }
            );
        }
    };

    const OnClickForceUpdateButton = () => {
        window.navigator.serviceWorker.getRegistrations().then((registrations) => {
            for (let registration of registrations) {
                registration.unregister();
            }
        });
        window.location.reload();
    };

    /*
    const Migration = () => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            const userId = user.uid;
            const database = getDatabase(firebaseApp);

            const dbRef = ref(database, `/taskLists/${userId}`);

            onValue(dbRef, (snapshot) => {
                const isExsits = snapshot.exists();
                if (isExsits === true) {
                    const data = snapshot.val();
                    console.log(data);

                    for (const key in data) {
                        console.log(key);

                        const taskList = data[key] as TaskList;

                        const listName = taskList.Name;

                        const taskRef = ref(database, `/tasks/${userId}/${listName}`);

                        onValue(taskRef, (snapshot) => {
                            const isExsits = snapshot.exists();
                            if (isExsits === true) {
                                const tasks = snapshot.val();

                                console.log(tasks);

                                for (const taskId in tasks) {
                                    const migrationTaskRef = ref(database, `/tasks/${userId}/${taskList.Id}/${taskId}`);
                                    set(migrationTaskRef, tasks[taskId]);
                                }
                            }
                        });
                    }
                }
            });
        }
    };
    */

    return (
        <div className='settingPage'>
            <Breadcrumbs secondLevelTitle='設定'></Breadcrumbs>
            <div className='content setting-content content-warp'>
                <div className='inner-content25 content-center'>
                    <span className='bold'>登録氏名変更</span>
                </div>
                <div className='inner-content75'>
                    <form onSubmit={OnSubmitChangeUserNameForm}>
                        <FormMessage isOk={isPasswordFormOk} message={passwordChangeMessage}></FormMessage>
                        <div className='input-group'>
                            <label>登録氏名</label>
                            <input type='text' name='name' defaultValue={username}></input>
                        </div>
                        <div className='center-big-button-container'>
                            <button type='submit' className='center-big-button'>
                                登録氏名変更
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className='content setting-content content-warp'>
                <div className='inner-content25 content-center'>
                    <span className='bold'>パスワード変更</span>
                </div>
                <div className='inner-content75'>
                    <form onSubmit={OnSubmitChangePasswordForm}>
                        <FormMessage isOk={isPasswordFormOk} message={passwordChangeMessage}></FormMessage>
                        <div className='input-group'>
                            <label>新パスワード</label>
                            <input type='password' name='password'></input>
                        </div>
                        <div className='input-group'>
                            <label>新パスワード再入力</label>
                            <input type='password' name='retype-password'></input>
                        </div>
                        <div className='center-big-button-container'>
                            <button type='submit' className='center-big-button'>
                                パスワード変更
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className='content setting-content content-warp'>
                <div className='inner-content25 content-center'>
                    <span className='bold'>強制アップデート</span>
                </div>
                <div className='inner-content75 content-center'>
                    <div className='center-big-button-container'>
                        <button type='button' className='center-big-button' onClick={OnClickForceUpdateButton}>
                            強制アップデート
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Setting;
