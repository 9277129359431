import {getAuth, browserLocalPersistence, setPersistence, onAuthStateChanged} from 'firebase/auth';
import React, {CSSProperties, useEffect, useState} from 'react';
import {useNavigate, useLocation, Link} from 'react-router-dom';
import {useAuthContext} from '../Components/AuthComponents';
import FormMessage from '../Components/FormMessage';

import './css/Login.css';

interface location {
    pathname: string;
    state: {
        from: {
            pathname: string;
        };
    };
}

const Login: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation() as location;

    const from = location.state?.from?.pathname || '/';

    console.log(`login:${from}`);

    const [errorMessage, SetErrorMessage] = useState<string>('');
    const [isLoginFormOk, SetIsLoginFormOk] = useState<boolean>(false);
    const [loadStyle, SetLoadStyle] = useState<CSSProperties>({});

    const authContext = useAuthContext();

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, () => {
            const user = auth.currentUser;
            if (user) {
                SetLoadStyle({display: 'none'});

                navigate(from);
            }
        });

        setTimeout(() => {
            SetLoadStyle({display: 'none'});
        }, 1000);
    });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);
        const mailAddress = formData.get('mailAddress') as string;
        const password = formData.get('password') as string;

        const auth = getAuth();

        SetErrorMessage('');

        setPersistence(auth, browserLocalPersistence)
            .then(() => {
                authContext.signin(mailAddress, password, (error, setUser) => {
                    if (error !== undefined) {
                        const errorCode = error.code;
                        const errorMessage = error.message;

                        SetIsLoginFormOk(false);
                        SetErrorMessage(`${errorCode}: ${errorMessage}`);

                        if (errorCode === 'auth/wrong-password') {
                            SetErrorMessage('パスワードが違います。');
                        } else if (errorCode === 'auth/user-not-found') {
                            SetErrorMessage('メールアドレスが登録されていません。');
                        } else if (errorCode === 'auth/invalid-email') {
                            SetErrorMessage('無効なメールアドレスです。');
                        } else if (errorCode === 'auth/too-many-requests') {
                            SetErrorMessage('ログイン試行回数が多すぎます。');
                        } else if (errorCode === 'auth/internal-error') {
                            SetErrorMessage('内部エラーです。');
                        }
                    } else {
                        onAuthStateChanged(auth, (user) => {
                            if (user) {
                                setUser(user);
                                const pathname = location.pathname;

                                if (pathname === '/login') {
                                    console.log(authContext);
                                    navigate(from, {replace: true});
                                }
                            }
                        });
                    }
                });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div className='loginPage'>
            <div className='load' style={loadStyle}>
                <div className='loader'>Loading...</div>
            </div>
            <div className='content content-warp'>
                <div id='login-form-contaner' className='content-max'>
                    <form onSubmit={handleSubmit} id='login-form'>
                        <FormMessage isOk={isLoginFormOk} message={errorMessage}></FormMessage>
                        <div className='input-group'>
                            <label>メールアドレス</label>
                            <input name='mailAddress' type='email' placeholder='mailAddress' />
                        </div>
                        <div className='input-group'>
                            <label>パスワード</label>
                            <input name='password' type='password' placeholder='password' />
                        </div>
                        <div className='center-big-button-container'>
                            <button type='submit' className='center-big-button'>
                                ログイン
                            </button>
                        </div>
                    </form>
                </div>
                <div id='login-bottom-menu' className='contet-max'>
                    <ul>
                        <li className='nav-item'>
                            <Link to='/register'>新規登録</Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/lostpassword'>パスワードを忘れた方</Link>
                        </li>
                        <li className='nav-item'>
                            <a href='https://solarsail.work/contact' target='_blank' rel='noreferrer'>
                                お問い合わせ<span className='material-symbols-outlined icon-small'>exit_to_app</span>
                            </a>
                        </li>
                        <li className='nav-item'>
                            <Link to='/releaseNote'>リリースノート</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Login;
