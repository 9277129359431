class TaskList {
    /**
     * タスクリストIDを取得/設定します。
     */
    public Id: string = '';

    /**
     * タスクリスト名を取得/設定します。
     */
    public Name: string = '';

    /**
     * タスクの作成日時を取得/設定します。
     */
    public DateCreated: number = new Date().getTime();
}

type TaskLists = { [key: string]: TaskList };

export default TaskList;
export type { TaskLists }