import {getAuth} from 'firebase/auth';
import React, {CSSProperties, useEffect, useState} from 'react';
import ToDoDatabase from '../Firebase/ToDoDatabase';
import Task from '../Models/Task';
import {RandomText, ScrollTop} from '../Utils/Util';

import './css/TaskEdit.css';

type EditTaskProp = {
    Task: Task | undefined;
    CurrentListName: string;
    Callback: VoidFunction;
};

const TaskEdit: React.FC<EditTaskProp> = (props: EditTaskProp) => {
    const [CurrentTask, SetCurrentTask] = useState<Task | undefined>(undefined);
    const [TaskValue, SetTaskValue] = useState('');

    const [AddButtonClassName, SetAddButtonClassName] = useState<string>('');

    const [AddModalClassName, SetAddModalClassName] = useState<string>('');
    const [AddModalStyle, SetAddModalStyle] = useState<CSSProperties>({display: 'none'});

    useEffect(() => {
        if (props.Task !== undefined) {
            ModalShow(true);

            SetCurrentTask(props.Task);
            SetTaskValue(props.Task.Value);

            FocusTaskTextarea();
        }
    }, [props.Task]);

    const OnChangeAddTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        SetTaskValue(event.target.value);
    };

    const OnForcusAddTextarea = () => {
        SetAddButtonClassName('add-button-active');
    };

    const OnBlurAddTextarea = () => {
        SetAddButtonClassName('');
    };

    const OnKeyDownAddTextarea = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const shiftKey = event.shiftKey;
        const downKey = event.key;

        if (shiftKey === true && downKey === 'Enter') {
            event.preventDefault();
            SaveTask();
            SetTaskValue('');
        }
    };

    const OnClickOpenModal = () => {
        SetCurrentTask(new Task());
        ModalShow(true);

        FocusTaskTextarea();
    };

    const OnModalClose = () => {
        ModalShow(false);
        props.Callback();
    };

    const ModalShow = (isShow: boolean) => {
        if (isShow === true) {
            const top = ScrollTop() + 30;
            SetAddModalStyle({display: 'block', top: top});
            SetAddModalClassName('modal');
        } else {
            SetAddModalStyle({display: 'none'});
            SetAddModalClassName('');
        }
    };

    const SaveTask = () => {
        if (CurrentTask !== undefined && TaskValue !== '') {
            if (CurrentTask.TaskId === '') {
                CurrentTask.TaskId = RandomText();
            }

            CurrentTask.Value = TaskValue;

            const auth = getAuth();
            const userId = auth.currentUser?.uid;
            if (userId !== undefined) {
                ToDoDatabase.SaveTask(userId, props.CurrentListName, CurrentTask.TaskId, CurrentTask);

                SetTaskValue('');
                ModalShow(false);
            }
        }
    };

    const FocusTaskTextarea = () => {
        const taskTextAreaElement = document.getElementById('task-input-textarea');

        if (taskTextAreaElement != null) {
            taskTextAreaElement.focus();
        }
    };

    return (
        <div className='edit-task'>
            <button onClick={OnClickOpenModal} id='open-modal-button'>
                <span className='material-symbols-outlined'>add</span><span className='button-text'>タスクの追加</span>
            </button>
            <div id='add-modal' className={AddModalClassName} style={AddModalStyle}>
                <div className='mobal-close'>
                    <span className='material-symbols-outlined symbol-animation' onClick={OnModalClose}>
                        cancel
                    </span>
                </div>
                <div className='modal-content'>
                    <textarea
                        placeholder='Shift + Enterで保存'
                        onChange={OnChangeAddTextarea}
                        onFocus={OnForcusAddTextarea}
                        onBlur={OnBlurAddTextarea}
                        onKeyDown={OnKeyDownAddTextarea}
                        value={TaskValue}
                        id='task-input-textarea'
                    ></textarea>
                    <button onClick={SaveTask} id='add-button' className={AddButtonClassName}>
                        <span className='material-symbols-outlined cursor-pointer'>add</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TaskEdit;
